<template>
    <!-- eslint-disable vue/no-v-model-argument -->
    <main class="grid">
        <!-- LOADER -->
        <!-- <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#007680" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" /> -->
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#0C0C0C" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />
        <div v-show="!loader.isLoading" class="col-10 m-auto">
            <!-- HEADER -->
            <div class="card">
                <h1 class="m-0">{{ $t("endpoints.profile.header") }}</h1>
            </div>

            <!-- INDEX -->
            <div class="card grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
                <div class="col-12 xl:col-6">
                    <!-- ICON -->
                    <div class="col-12 xl:col-12 text-center" :aria-label="$t('accesibility.register.email')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10em" height="10em" viewBox="0 0 80 80" version="1.1" aria-hidden="true">
                            <!-- <circle fill="#007680" width="30" height="30" cx="40" cy="40" r="40" role="none" aria-hidden="true" /> -->
                            <circle fill="#0C0C0C" width="30" height="30" cx="40" cy="40" r="40" role="none" aria-hidden="true" />
                            <text role="none" aria-hidden="true" x="50%" y="50%" style="color: #ffffff; line-height: 1; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" alignment-baseline="middle" text-anchor="middle" font-size="32" font-weight="400" dy=".1em" dominant-baseline="middle" fill="#ffffff">
                                {{ initial }}
                            </text>
                        </svg>
                        <span style="display: block; font-size: 1.4rem; margin-top: 1rem">{{ email }}</span>
                    </div>

                    <!-- USERNAME -->
                    <h3>{{ userMode }}: {{ username }}</h3>

                    <Divider layout="horizontal" align="center" role="none" aria-hidden="true"> </Divider>
                    <br role="none" aria-hidden="true" />

                    <div class="p-fluid" role="none">
                        <!-- PASSWORD -->
                        <div class="field" :aria-label="$t('accesibility.register.pass')">
                            <label for="password1">{{ $t("endpoints.profile.body.password") }}</label>
                            <InputText id="password1" type="password" value="password" disabled aria-disabled="true" />
                        </div>

                        <!-- CENTER -->
                        <div class="field" :aria-label="$t('accesibility.register.center')">
                            <label for="center">{{ $t("endpoints.profile.body.center") }}</label>
                            <InputText id="center" type="text" :value="centerName" disabled aria-disabled="true" />
                        </div>

                        <!-- FULL NAME -->
                        <div class="field" :aria-label="$t('accesibility.register.fullName')">
                            <label for="full_name">{{ $t("endpoints.profile.body.full_name") }}</label>
                            <InputText id="full_name" type="text" :value="username" disabled aria-disabled="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { getUserInfo } from "../utils/auth.js";
import { capitalizeFirstLetter } from "../utils/utils.js";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { mapState } from "vuex";

const CentersRepository = RepositoryFactory.get("centers");

export default {
    components: {
        Loading
    },
    data() {
        return {
            userMode: "",
            username: "",
            email: "",
            initial: "",
            centerName: "",
            selectedLanguage: "",
            languages: [
                { id: 1, name: "Español" },
                { id: 2, name: "Ingles" }
            ]
        };
    },
    computed: {
        ...mapState(["loader"])
    },
    watch: {
        "$i18n.locale": function () {
            this.getUserData();
        }
    },
    created() {
        document.title = this.$t("accesibility.title.profile");
        this.getUserData();
    },
    methods: {
        async getUserData() {
            let { username, email, user_mode, center_id } = getUserInfo();
            this.username = capitalizeFirstLetter(username);
            this.email = email;
            this.initial = username.charAt(0).toUpperCase();
            let response = await CentersRepository.getCenter(center_id);
            this.centerName = response.data.name;
            if (user_mode == "teacher") {
                this.userMode = this.$t("endpoints.profile.body.user_mode.teacher");
            } else {
                this.userMode = this.$t("endpoints.profile.body.user_mode.student");
            }
        }
    }
};
</script>
